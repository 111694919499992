<template>
  <div>
      <CCard>
      <CCardHeader>
        <CIcon name="cil-user" /><strong>Wnp Search Payload</strong>
      </CCardHeader>
      <CCardBody>
          <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect
                size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
      <!-- Main table element -->
      <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      table-filter
      light
    >

    <template #action="{item}">
        <td>
        <span @click="deletedata(item)">
        <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Inactive" style="cursor: pointer;" ></CImg>
        </span>
        </td>
    </template>
    </CDataTable>
    </CCardBody></CCard>


  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import ManagerService from './../../services/managerControlService'
  export default {
    components: {Multiselect},
    data() {
      return {
        input:{},
        tableperpage:20,
        perPage:[20,50,100],
        items: [],
        fields: [
          { key: 'browsername', label: 'Browser Name'},
          { key: 'browserversion', label: 'Browser Version'},
          { key: 'deviceType', label: 'Device Type'},
          { key: 'osname', label: 'Os Name'},
          { key: 'osversion', label: 'Os Version'},
          { key: 'protocol', label: 'Protocol'},
          {key: 'action',label: 'Action'},
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        users:[],
      }
    },
    computed: {
      
    },
   async mounted() {
      if(window.localStorage.getItem('user') == 'null' || window.localStorage.getItem('user') != 'manager') {
        alert('Please Enter User Name and Password')
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          window.localStorage.setItem("email", null);
        this.$router.push('/pages/login')
      }
      if(localStorage.getItem("wpn_data") != null)
      this.tableperpage = parseInt(localStorage.getItem("wpn_data"));
      this.getData()
      
    },
    methods: {
      checkPage(){
          localStorage.setItem("wpn_data", this.tableperpage);
        },
       async getData(){
            let response
        try {
            response = await ManagerService.getwpnsearch()
            this.users = response.message
            this.users = response.message.filter(t=>{
            if(t.role != 'manager')
            return t
            })
            this.items = this.users
            this.totalRows = this.items.length
        } catch(e){
            console.error("Error in login")
            console.log(e)
        }
        },
     async deletedata(formData){
        let response
        try {
          response = await ManagerService.deletewpnsearch({"wnpid":formData._id})
          if (response.result == true) {

              Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              this.getData()
              }
              else {
                  Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
              }
        } catch(e){
          console.error("Error in login")
          console.log(e)
        }
      },
    }
  }
</script>

<style>
  .b_card{
    background-color: white;
    /* box-shadow: rgb(136, 136, 136) 2px 5px 7px 2px; */
  }

  .bg_page {
    background: white;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
    /*position: fixed;*/
    /*background: rgb(2, 0, 36);*/
    /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
  }
  .logout:hover{
    background-color: #b82e2b;
  }
  .v_tag{
    overflow-y: scroll;
    max-height: 100px;
  }

</style>
